import React from 'react';
import { DisplayProcessTime } from '../Transaction/helper';
import dayjs from 'dayjs';

const generateChecklistItem = (formikData, dataOrder) => {
    let checklistText = `
--------------------------------
Checklist Tambahan

Nama : ${formikData?.customer?.name}
Tanggal Masuk : ${dayjs(formikData?.createdAt).format('DD-MMM-YYYY')}

Treatment : ${dataOrder?.treatment?.name} - ${DisplayProcessTime(dataOrder?.process_time)}
Tanggal Selesai : ${dayjs(dataOrder?.due_date).format('DD-MMM-YYYY')}

Item
${dataOrder?.item?.brand} ${dataOrder?.item?.name}
Warna : ${dataOrder?.item?.color}

Catatan : 
${dataOrder?.description || '-'}

Teknisi: ____________________
`;

    return checklistText;
};

const generateSlip = (formikData, dataOrder) => {
    return `
---------quality check---------
Customer: ${formikData?.customer?.name}
Item: ${dataOrder?.item?.brand} - ${dataOrder?.item?.name}
Treatment: ${dataOrder?.treatment?.name}

Teknisi: ____________________
Status: [ ]Pass  [ ]Need Review
Tgl QC: __/__/__ Paraf: ______
--------------------------------
    `;
};

export const PrintChecklistSatuan = async (formikData) => {
    try {

        const options = {
            filters: [{ name: 'RPP02' }], // Sesuaikan dengan nama printer Anda
            optionalServices: ['e7810a71-73ae-499d-8c15-faa9aef0c3f2'], // UUID layanan printer Bluetooth Anda
        };

        const device = await navigator.bluetooth.requestDevice(options);
        const server = await device.gatt.connect();
        const service = await server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2');
        const characteristic = await service.getCharacteristic('bef8d6c9-9c21-4c9e-b632-bd58c1009f9f');

        if (characteristic === null) {
            console.error('Bluetooth characteristic is null');
            return false;
        }

        const encoder = new TextEncoder();

        const clearData = async () => {
            await characteristic.writeValue(encoder.encode(''));
        };

        const printData = async (data) => {
            await clearData();
            await characteristic.writeValue(encoder.encode(data));
        };

        // Fungsi untuk mencetak satu data saja
        const generateSingleInvoice = async (dataOrder) => {
            const checklistItem = generateChecklistItem(dataOrder, dataOrder?.order_details[0]);
            await printData(checklistItem);

            // Tambahkan slip QC check
            const slip = generateSlip(dataOrder, dataOrder?.order_details[0]);
            await printData(slip);
        };

        // Panggil fungsi untuk cetak single data

        await generateSingleInvoice(formikData);

        return true;

    } catch (error) {
        console.error(error);
        return false;
    }
};
