import React from 'react';
import { DisplayProcessTime } from '../Transaction/helper';
// dayjs
import dayjs from 'dayjs';
import { formatCurrency } from '../../helper/formatCurrency';

// Helper untuk memastikan teks tidak melebihi 32 karakter
const truncateText = (text, maxLength = 32) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) : text;
};

// Helper untuk membuat spasi rata kanan
const rightAlign = (text, value, length = 32) => {
    const textLength = text.length;
    const valueLength = value.toString().length;
    const spaces = length - textLength - valueLength;
    return `${text}${' '.repeat(Math.max(0, spaces))}${value}`;
};

// Fungsi untuk mengirim data dalam chunk kecil
const sendChunkedData = async (encoder, characteristic, text) => {
    const CHUNK_SIZE = 480; // Sedikit di bawah 512 untuk safety margin
    let index = 0;
    const textBytes = encoder.encode(text);

    while (index < textBytes.length) {
        const chunk = textBytes.slice(index, index + CHUNK_SIZE);
        await characteristic.writeValue(chunk);
        index += CHUNK_SIZE;
        // Delay kecil antara chunk
        await new Promise(resolve => setTimeout(resolve, 50));
    }
};

export const generateHeaderInvoice = (dataOrder) => {
    const { customer, payment } = dataOrder;
    return `
================================
           KATSIKAT
   PREMIUM SHOES TREATMENT
================================
Jl Grindulu No 24, Bunulrejo
Kec. Blimbing, Kota Malang
Telp: 0877-9545-2475
--------------------------------
CUSTOMER
${truncateText(`Nama   : ${customer.name}`, 32)}
${truncateText(`Alamat : ${customer.address}`, 32)}

${truncateText(`No.Inv : ${payment.code}`, 32)}
${truncateText(`Tgl    : ${dayjs(dataOrder.createdAt).format('DD/MM/YY HH:mm')}`, 32)}
--------------------------------
`;
};

export const generateOrderDetails = (orderDetails) => {
    let result = '';

    // Mengelompokkan treatment yang sama
    const groupedTreatments = orderDetails.reduce((acc, detail) => {
        const treatmentKey = detail.treatment.id;
        if (!acc[treatmentKey]) {
            acc[treatmentKey] = {
                treatment: detail.treatment,
                process_time: detail.process_time,
                count: 1,
                total_price: parseFloat(detail.price),
                total_final_price: parseFloat(detail.final_price),
                descriptions: [detail.description].filter(Boolean), // filter falsy values
                discount: detail.discount
            };
        } else {
            acc[treatmentKey].count += 1;
            acc[treatmentKey].total_price += parseFloat(detail.price);
            acc[treatmentKey].total_final_price += parseFloat(detail.final_price);
            if (detail.description) {
                acc[treatmentKey].descriptions.push(detail.description);
            }
        }
        return acc;
    }, {});

    // Generate output untuk setiap grup treatment
    for (const group of Object.values(groupedTreatments)) {
        const {
            treatment,
            process_time,
            count,
            total_price,
            total_final_price,
            descriptions,
            discount
        } = group;

        // Treatment name dengan jumlah jika lebih dari 1
        const treatmentName = count > 1
            ? `${treatment.name} (x${count})`
            : treatment.name;
        result += `${truncateText(treatmentName, 32)}\n`;

        // Process time
        // result += `${truncateText(`${DisplayProcessTime(process_time)}`, 32)}\n`;

        // Descriptions (max 2 baris jika ada multiple items)
        // if (descriptions.length > 0) {
        //     if (descriptions.length === 1) {
        //         result += `${truncateText(`Note: ${descriptions[0]}`, 32)}\n`;
        //     } else {
        //         result += `${truncateText(`Note: ${descriptions.length} items`, 32)}\n`;
        //     }
        // }

        // Price details
        if (count > 1) {
            result += rightAlign(`Harga (${formatNumber(total_price / count)} x ${count})`, formatNumber(total_price)) + '\n';
        } else {
            result += rightAlign('Harga', formatNumber(total_price)) + '\n';
        }

        // Show discount if exists
        if (discount && total_price !== total_final_price) {
            const selisihAmount = total_final_price - total_price;
            console.log('process time', process_time);
            if (process_time === 'regular' && selisihAmount < 0) {
                result += rightAlign('Diskon', `${formatNumber(selisihAmount)}`) + '\n';
            } else {
                result += rightAlign('Tambahan', `${formatNumber(selisihAmount)}`) + '\n';
            }
        }

        result += rightAlign('Final', formatNumber(total_final_price)) + '\n';
        result += '--------------------------------\n';
    }

    console.log(result);

    return result;
};

export const generatePaymentSummary = (payment) => {
    const {
        final_price,
        paid_amount,
        remaining_amount,
        status,
        payment_details
    } = payment;

    let paymentStatus = '';
    if (status === 'unpaid') {
        paymentStatus = '(Belum Lunas)';
    } else if (status === 'dp') {
        paymentStatus = `(DP ${formatNumber(paid_amount)})`;
    } else {
        paymentStatus = '(Lunas)';
    }

    let summary = `\n${rightAlign('Sub Total', formatNumber(final_price))}`;

    // Tampilkan detail pembayaran jika ada
    if (payment_details && payment_details.length > 0) {
        payment_details.forEach(detail => {
            summary += `\n${rightAlign(`Bayar (${detail.method})`, formatNumber(detail.amount))}`;
        });
    }

    // Tampilkan sisa jika ada
    if (remaining_amount > 0) {
        summary += `\n${rightAlign('Sisa', formatNumber(remaining_amount))}`;
    }

    summary += `\n${rightAlign('Status', paymentStatus)}`;
    summary += '\n================================\n';

    return summary;
};

const formatNumber = (num) => {
    return parseInt(num).toLocaleString('id-ID');
};

export const generateFooterInvoice = () => {
    return `
          GARANSI
* 24 Jam untuk Treatment Cuci
* 1 Bulan untuk Repair, Reglue,
  dan Repaint

     Terima Kasih Telah
    Mempercayakan Sepatu
    Kesayangan Anda Pada
         KATSIKAT

     Instagram: @katsikat.id
     WA: 0877-9545-2475
================================
`;
};

export const PrintInvoice = async (orderData) => {

    try {
        const options = {
            filters: [{ name: 'RPP02' }],
            optionalServices: ['e7810a71-73ae-499d-8c15-faa9aef0c3f2'],
        };

        const device = await navigator.bluetooth.requestDevice(options);
        const server = await device.gatt.connect();
        const service = await server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2');
        const characteristic = await service.getCharacteristic('bef8d6c9-9c21-4c9e-b632-bd58c1009f9f');

        if (!characteristic) {
            throw new Error('Bluetooth characteristic is null');
        }

        const encoder = new TextEncoder();

        // Kirim data dalam chunk-chunk
        await sendChunkedData(encoder, characteristic, generateHeaderInvoice(orderData));
        await sendChunkedData(encoder, characteristic, generateOrderDetails(orderData.order_details));
        await sendChunkedData(encoder, characteristic, generatePaymentSummary(orderData.payment));
        await sendChunkedData(encoder, characteristic, generateFooterInvoice());

        // Cut paper command
        // await characteristic.writeValue(encoder.encode('\x1D\x56\x41\x10'));

        return true;
    } catch (error) {
        console.error('Printer error:', error);
        return false;
    }
};