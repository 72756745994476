import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker, Button, Radio, Card, Col, Row, Typography } from 'antd';
import { ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import ModalAddCustomer from '../component/ModalAddCustomer';
import { API_URL, fetchData, postData, putData } from '../../helper/api';
import { customerDataModel } from '../../models/customerData';
import { optionRadioPaymentMethod, optionRadioPaymentStatus, optionRadioTransaction } from '../../models/generalData';
import * as dayjs from 'dayjs'
import { FormikDataTransactionToPostRequest, FormikDataTransactionToUpdateRequest, InitialDataToFormikDataTransaction, PaymentMethodDisplay, PostDataTransactionModel } from '../../models/orderData';
import { EditOutlined, EllipsisOutlined, SettingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { MdDelete } from 'react-icons/md';
import AddItem from './AddItem';
import { DisplayProcessTime, getProgressColor, TRANSACTION_DETAIL_OPTION, TRANSACTION_PAYMENT_OPTION, TRANSACTION_RADIO_OPTION, updatePayment, validationSchema } from './helper';
import { formatCurrency, formatNumber } from '../../helper/formatCurrency';
import generatePDF from 'react-to-pdf';
import Invoice from '../component/pdf/Invoice';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import UploadPhotoEvidence from './UploadPhotoEvidence';
import { InvoiceDataGenerate, PrintInvoice } from '../Printer/PrintInvoice';
import { PrintChecklist } from '../Printer/CetakChecklist';
import { PrintChecklistSatuan } from '../Printer/CetakChecklistSatuan';
import axios from 'axios';
import { FaHistory, FaMoneyBillWave, FaClock, FaUniversity, FaQrcode, FaSave, FaClipboardCheck, FaPrint, FaFileDownload, FaEdit, FaTrash, FaTag, FaInfoCircle, FaCalendar, FaPlus, FaStore, FaExclamationCircle, FaBoxes, FaGlobe, FaUser, FaUserPlus, FaChartPie } from 'react-icons/fa';
import { useTransaction } from './contexts/TransactionContext';
import { useGeneral } from '../../contexts/GeneralContext';


const { Meta } = Card;

const { Option } = Select;

const PaymentModal = ({
    visible,
    onCancel,
    onSubmit,
    loading,
    financeAccounts,
    paymentDetail,
    setPaymentDetail,
    formik
}) => {
    // Pindahkan state lokal ke dalam modal
    const [localPaymentDetail, setLocalPaymentDetail] = useState(paymentDetail);
    const [formikValues, setFormikValues] = useState(formik.values);
    // Reset local state ketika modal dibuka
    useEffect(() => {
        setLocalPaymentDetail(paymentDetail);
    }, [visible, paymentDetail]);

    const calculatePayment = () => {
        const totalPayment = formik.values.payment.payment_details.reduce((total, item) => {
            return total + item.final_amount;
        }, 0);

        return totalPayment;
    }

    const handleSubmit = () => {

        formik.setFieldValue('payment.payment_details', [...formik.values.payment.payment_details, localPaymentDetail]);

        onSubmit();
    };

    return (
        <Modal
            title="Tambah Pembayaran"
            open={visible}
            onCancel={onCancel}
            footer={null}
        >
            <Form layout="vertical">
                <Form.Item
                    label={
                        <span className="text-gray-700 font-medium mb-3">Payment Method</span>
                    }
                >
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                        {financeAccounts.map((account) => (
                            <div
                                key={account.id}
                                className={`
                                    p-4 rounded-lg border-2 cursor-pointer transition-all duration-200
                                    ${localPaymentDetail.account_id === account.id
                                        ? account.type === 'cash'
                                            ? 'border-green-500 bg-green-50'
                                            : account.type === 'bank'
                                                ? 'border-blue-500 bg-blue-50'
                                                : 'border-purple-500 bg-purple-50'
                                        : 'border-gray-200 hover:border-gray-300 hover:shadow-md'
                                    }
                                `}
                                onClick={() => {
                                    const selectedAccount = financeAccounts.find(acc => acc.id === account.id);
                                    formik.setFieldValue('payment.method', selectedAccount.type);
                                    setLocalPaymentDetail({
                                        ...localPaymentDetail,
                                        account_id: account.id,
                                        method: selectedAccount.type === 'bank' ? 'bank_transfer' : selectedAccount.type,
                                    });
                                }}
                            >
                                <div className="flex flex-col items-center gap-3">
                                    {/* Icon berdasarkan tipe */}
                                    {account.type === 'cash' && (
                                        <FaMoneyBillWave
                                            className={`text-2xl ${localPaymentDetail.account_id === account.id
                                                ? 'text-green-500'
                                                : 'text-gray-400'
                                                }`}
                                        />
                                    )}
                                    {account.type === 'bank' && (
                                        <FaUniversity
                                            className={`text-2xl ${localPaymentDetail.account_id === account.id
                                                ? 'text-blue-500'
                                                : 'text-gray-400'
                                                }`}
                                        />
                                    )}
                                    {account.type === 'qris' && (
                                        <FaQrcode
                                            className={`text-2xl ${localPaymentDetail.account_id === account.id
                                                ? 'text-purple-500'
                                                : 'text-gray-400'
                                                }`}
                                        />
                                    )}

                                    {/* Account Name */}
                                    <div className="text-center">
                                        <div className="font-medium text-gray-800">
                                            {account.name}
                                        </div>
                                        <div className={`text-sm mt-1 ${localPaymentDetail.account_id === account.id
                                            ? account.type === 'cash'
                                                ? 'text-green-600'
                                                : account.type === 'bank'
                                                    ? 'text-blue-600'
                                                    : 'text-purple-600'
                                            : 'text-gray-500'
                                            }`}>
                                            {account.type.toUpperCase()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {loading && (
                        <div className="flex justify-center mt-4">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                        </div>
                    )}
                </Form.Item>

                {localPaymentDetail.method !== 'cash' && (
                    <Form.Item label="Upload Bukti Bayar">
                        <UploadPhotoEvidence
                            value={localPaymentDetail.evidence_photo}
                            onChange={(url) => {
                                setLocalPaymentDetail({
                                    ...localPaymentDetail,
                                    evidence_photo: url
                                });
                            }}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    label={
                        <span className="text-gray-700 font-medium">Payment Amount</span>
                    }
                >
                    <div className="space-y-4">
                        {/* Main Input Amount */}
                        <div className="relative">
                            <div className="absolute left-4 top-1/2 -translate-y-1/2">
                                <span className="text-gray-500 font-medium">Rp</span>
                            </div>
                            <Input
                                type='text'
                                value={formatCurrency(parseInt(localPaymentDetail.amount) || 0)}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    const numericValue = parseFloat(value.replace(/[^\d]/g, ''));
                                    const newValue = isNaN(numericValue) ? 0 : numericValue;
                                    const finalAmount = newValue + (localPaymentDetail.fee || 0) + (localPaymentDetail.interest || 0);

                                    setLocalPaymentDetail({
                                        ...localPaymentDetail,
                                        amount: newValue,
                                        final_amount: finalAmount
                                    });
                                }}
                                className="pl-12 pr-4 py-3 text-lg font-medium rounded-lg border-2 border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200"
                            />
                        </div>

                        {/* Payment Details Summary */}
                        <div className="bg-gray-50 rounded-lg p-4 space-y-2">
                            <div className="flex justify-between items-center text-sm">
                                <span className="text-gray-600">Base Amount</span>
                                <span className="font-medium text-gray-800">
                                    {formatCurrency(parseInt(localPaymentDetail.amount) || 0)}
                                </span>
                            </div>

                            {localPaymentDetail.fee > 0 && (
                                <div className="flex justify-between items-center text-sm">
                                    <span className="text-gray-600">Fee</span>
                                    <span className="font-medium text-gray-800">
                                        + {formatCurrency(localPaymentDetail.fee)}
                                    </span>
                                </div>
                            )}

                            {localPaymentDetail.interest > 0 && (
                                <div className="flex justify-between items-center text-sm">
                                    <span className="text-gray-600">Interest</span>
                                    <span className="font-medium text-gray-800">
                                        + {formatCurrency(localPaymentDetail.interest)}
                                    </span>
                                </div>
                            )}

                            {/* Total Amount */}
                            {(localPaymentDetail.fee > 0 || localPaymentDetail.interest > 0) && (
                                <div className="pt-2 mt-2 border-t border-gray-200">
                                    <div className="flex justify-between items-center">
                                        <span className="font-medium text-gray-700">Total Amount</span>
                                        <span className="font-bold text-lg text-blue-600">
                                            {formatCurrency(localPaymentDetail.final_amount)}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="space-y-3">
                            {/* Percentage based buttons */}
                            <div className="grid grid-cols-2 gap-2">
                                {[
                                    { label: 'Down Payment (50%)', value: formik.values.payment?.final_price * 0.5 },
                                    { label: 'Full Payment', value: formik.values.payment?.final_price }
                                ].map((option) => (
                                    <button
                                        key={option.label}
                                        type="button"
                                        onClick={() => {
                                            const finalAmount = option.value + (localPaymentDetail.fee || 0) + (localPaymentDetail.interest || 0);
                                            setLocalPaymentDetail({
                                                ...localPaymentDetail,
                                                amount: option.value,
                                                final_amount: finalAmount
                                            });
                                        }}
                                        className="flex flex-col items-center justify-center p-3 rounded-lg border-2 border-blue-100 bg-blue-50 hover:bg-blue-100 transition-colors duration-200"
                                    >
                                        <span className="text-blue-600 font-medium mb-1">{option.label}</span>
                                        <span className="text-sm text-blue-800">{formatCurrency(option.value)}</span>
                                    </button>
                                ))}
                            </div>

                            {/* Fixed amount buttons */}
                            <div className="grid grid-cols-4 gap-2">
                                {[50000, 100000, 200000, 500000].map((amount) => (
                                    <button
                                        key={amount}
                                        type="button"
                                        onClick={() => {
                                            const finalAmount = amount + (localPaymentDetail.fee || 0) + (localPaymentDetail.interest || 0);
                                            setLocalPaymentDetail({
                                                ...localPaymentDetail,
                                                amount: amount,
                                                final_amount: finalAmount
                                            });
                                        }}
                                        className={`px-3 py-2 text-sm font-medium rounded-lg transition-colors duration-200
                    ${amount <= formik.values.payment?.final_price
                                                ? 'text-gray-600 bg-gray-100 hover:bg-gray-200'
                                                : 'text-gray-400 bg-gray-50 cursor-not-allowed'}`}
                                        disabled={amount > formik.values.payment?.final_price}
                                    >
                                        {formatCurrency(amount)}
                                    </button>
                                ))}
                            </div>

                            {/* Remaining payment button */}
                            {formik.values.payment?.payment_details?.length > 0 && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        const paidAmount = formik.values.payment.payment_details.reduce(
                                            (total, detail) => total + parseFloat(detail.final_amount),
                                            0
                                        );
                                        const remainingAmount = formik.values.payment.final_price - paidAmount;
                                        const finalAmount = remainingAmount + (localPaymentDetail.fee || 0) + (localPaymentDetail.interest || 0);

                                        setLocalPaymentDetail({
                                            ...localPaymentDetail,
                                            amount: remainingAmount,
                                            final_amount: finalAmount
                                        });
                                    }}
                                    className="w-full p-3 rounded-lg border-2 border-green-100 bg-green-50 hover:bg-green-100 transition-colors duration-200"
                                >
                                    <span className="text-green-600 font-medium">Pay Remaining Amount</span>
                                    <span className="block text-sm text-green-800 mt-1">
                                        {formatCurrency(
                                            formik.values.payment.final_price -
                                            formik.values.payment.payment_details.reduce(
                                                (total, detail) => total + parseFloat(detail.final_amount),
                                                0
                                            )
                                        )}
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                </Form.Item>

                <div className="flex gap-2">
                    <Button className="flex-1" onClick={onCancel}>
                        Batal
                    </Button>
                    <Button
                        className="flex-1"
                        onClick={() => {
                            setLocalPaymentDetail({
                                payment_id: formikValues.payment?.id,
                                account_id: null,
                                method: '',
                                amount: 0,
                                fee: 0,
                                interest: 0,
                                final_amount: 0,
                                evidence_photo: null
                            });
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        className="flex-1"
                        onClick={handleSubmit}
                        disabled={!localPaymentDetail.account_id || !localPaymentDetail.amount}
                    >
                        Submit
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};


const ModalAddTransaction = (props) => {
    const { open, setOpen, initialData } = props;
    const { financeAccounts, DataCustomerOption } = useTransaction();

    const location = useLocation();
    const history = useHistory();
    const [selectRadio, setSelectRadio] = useState(TRANSACTION_RADIO_OPTION.TRANSACTION);
    const [selectTransactionDetailOption, setSelectTransactionDetailOption] = useState(TRANSACTION_DETAIL_OPTION.ITEMS);
    const [indexEditItem, setIndexEditItem] = useState(null);
    const [selectPaymentStatus, setSelectPaymentStatus] = useState(TRANSACTION_PAYMENT_OPTION.PAID);

    const [openModalAddCustomer, setOpenModalAddCustomer] = useState(false);
    const [openModalInvoice, setOpenModalInvoice] = useState(false);
    const [dataTransaction, setDataTransaction] = useState(PostDataTransactionModel)
    const { getProfile } = useGeneral();
    const [userData, setUserData] = useState(getProfile);

    // useState save data sementara
    const [dataCustomer, setDataCustomer] = useState({ ...customerDataModel });

    // Option Data Customer
    const [dataCustomerOption, setDataCustomerOption] = useState(DataCustomerOption);

    // Inisialisasi dengan nilai default
    const [newPaymentDetail, setNewPaymentDetail] = useState({
        payment_id: null,
        account_id: null,
        method: '',
        amount: 0,
        fee: 0,
        interest: 0,
        final_amount: 0,
        evidence_photo: null
    });

    const [loading, setLoading] = useState(false);

    // Tambahkan state untuk menyimpan payment details sebelumnya

    // Tambahkan state untuk modal pembayaran
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);

    const formik = useFormik({
        initialValues: PostDataTransactionModel,
        value: PostDataTransactionModel,
        // validationSchema: validationSchema,
        onSubmit: (values) => {
            if (initialData) {
                const dataPostTransaction = FormikDataTransactionToUpdateRequest(values, userData);
                if (dataPostTransaction.isError) {
                    toast.error(dataPostTransaction.errorMessages[0])
                    formik.setSubmitting(false);

                    return
                }
                putData(`/orders/${initialData.id}`, dataPostTransaction.data)
                    .then((res) => {
                        console.log(res);
                        setOpen(false);
                        formik.resetForm();
                        toast.success("Update Transaction Success");
                        const newLocation = {
                            ...location,
                            search: location.search + `&update=${initialData.id}`,
                        };
                        history.push(newLocation);
                    })
                    .catch((err) => {
                        console.log(err);
                        formik.setSubmitting(false);


                        toast.error("Update Transaction Failed");
                    })
            } else {
                const dataPostTransaction = FormikDataTransactionToPostRequest(values, userData);
                if (dataPostTransaction.isError) {
                    toast.error(dataPostTransaction.errorMessages[0])
                    formik.setSubmitting(false);

                    return
                }
                postData("/orders", dataPostTransaction.data)
                    .then((res) => {

                        console.log(res);

                        formik.setValues(PostDataTransactionModel)
                        setOpen(false);
                        formik.resetForm();

                        toast.success("Add Transaction Success");
                        const newLocation = {
                            ...location,
                            search: location.search + `&add=${res.data.data.id}`,
                        };
                        history.push(newLocation);

                    })
                    .catch((err) => {
                        console.log(err);
                        formik.setSubmitting(false);

                        toast.error("Add Transaction Failed");
                    })
            }

        },
    });

    useEffect(() => {
        setSelectRadio(TRANSACTION_RADIO_OPTION.TRANSACTION);
    }, [open]);

    useEffect(() => {
        setDataCustomerOption(DataCustomerOption);
    }, [DataCustomerOption]);

    useEffect(() => {
        if (!open) {
            formik.setValues(PostDataTransactionModel);
        }
        if (initialData) {
            formik.setValues(InitialDataToFormikDataTransaction(initialData));
            setSelectPaymentStatus(initialData.payment?.status);
        } else {
            formik.setValues(PostDataTransactionModel);
        }
    }, [initialData, open]);

    // useEffect(() => {
    //     // fetch data customer
    //     fetchData("/customers")
    //         .then((res) => {
    //             const dataCustomerList = res.data.data;
    //             const dataCustomerOptionTemp = [];
    //             dataCustomerList.map((item) => {
    //                 dataCustomerOptionTemp.push({
    //                     id: item.id,
    //                     name: item.name,
    //                     phone: item.phone,
    //                     ...item,
    //                 });
    //             });
    //             setDataCustomerOption(dataCustomerOptionTemp);
    //         })
    //         .catch((err) => {
    //             console.log("ERROR");
    //         });
    // }, [openModalAddCustomer]);

    useEffect(() => {
        if (selectRadio === TRANSACTION_RADIO_OPTION.PAYMENT) {
            // set field payment
            const total_price = formik.values.order_details.reduce((total, item) => {
                const price = parseFloat(item.price);
                return !isNaN(price) ? total + price : total;
            }, 0);

            const total_cost_processing_time = formik.values.order_details.reduce((total, item) => {
                const process_time_cost = parseFloat(item.process_time_cost);
                return !isNaN(process_time_cost) ? total + process_time_cost : total;
            }, 0);

            const total_final_price = formik.values.order_details.reduce((total, item) => {
                const final_price = parseFloat(item.final_price);
                return !isNaN(final_price) ? total + final_price : total;
            }, 0);


            formik.setFieldValue('payment', {
                ...formik.values.payment,
                code: formik.values?.payment?.code,
                total_process_time_cost: total_cost_processing_time,
                total_price: total_price + total_cost_processing_time,
                total_discount: total_final_price - total_price - total_cost_processing_time,
                final_price: total_final_price,
            });

            if (selectPaymentStatus === TRANSACTION_PAYMENT_OPTION.UNPAID) {
                formik.setFieldValue('payment', {
                    ...formik.values.payment,
                    code: formik.values?.payment?.code,
                    total_process_time_cost: total_cost_processing_time,
                    total_price: total_price + total_cost_processing_time,
                    total_discount: total_final_price - total_price - total_cost_processing_time,
                    final_price: total_final_price,
                    status: 'unpaid',
                    method: '',
                    date: '',
                    money: '',
                    change: '',
                });
            }
        }
    }, [selectRadio, selectPaymentStatus]);



    const handleAddItem = () => {
        formik.setFieldValue('order_details', [...formik.values.order_details, {
            id: '0',
            order_id: initialData ? initialData.id : '',
            employee_id: userData?.id || 1,
            treatment_id: '',
            item_id: '',
            discount_id: '',
            due_date: "",
            price: '',
            final_price: '',
            status: '',
            description: '',
            shoes_photos: [
            ],
        }]);


        setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.ADD_ITEM)
    }

    const updatePayment = () => {
        const finalPrice = formik.values.order_details.reduce((total, item) => {
            return total + parseFloat(item.final_price);
        }, 0);
        const paidAmount = formik.values.payment.payment_details.reduce((total, detail) => {
            return total + parseFloat(detail.final_amount);
        }, 0);
        const remainingAmount = finalPrice - paidAmount;
        const status = remainingAmount === 0 ? 'paid' : remainingAmount > 0 && remainingAmount < finalPrice ? 'dp' : 'unpaid';
        const method = formik.values.payment.payment_details.map(detail => detail.method).join(', ');

        formik.setFieldValue('payment.final_price', finalPrice);
        formik.setFieldValue('payment.remaining_amount', remainingAmount);
        formik.setFieldValue('payment.status', status);
        formik.setFieldValue('payment.method', method);
    }

    const handleDelete = (index) => {
        const values = [...formik.values.order_details];
        values.splice(index, 1);
        formik.setFieldValue('order_details', values);
        updatePayment();
    }

    const handleEdit = (index) => {
        setIndexEditItem(index);
        setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.EDIT_ITEM)
    }

    const handlePrintChecklist = (formikData, singleOrderDetail) => {
        // Siapkan data dengan hanya satu order detail
        const singleFormikData = {
            ...formikData,
            order_details: [singleOrderDetail],
        };

        // Panggil fungsi PrintChecklist dengan data yang telah disiapkan
        PrintChecklistSatuan(singleFormikData)
            .then(() => {
                console.log('Checklist printed successfully');
            })
            .catch((err) => {
                console.error('Failed to print checklist:', err);
            });
    };

    // Modifikasi handleSubmit untuk menyimpan payment detail
    const handleSubmit = () => {
        // Simpan state payment details sebelumnya
        // const updatedPayment = updatePayment(formik.values.payment, formik.values.order_details);
        // formik.setFieldValue('payment', updatedPayment);

        // Reset form pembayaran
        setNewPaymentDetail({
            payment_id: formik.values.payment?.id,
            account_id: null,
            method: '',
            amount: 0,
            fee: 0,
            interest: 0,
            final_amount: 0,
            evidence_photo: null
        });
    };

    return (
        <div>
            <Modal
                title={initialData ? 'Edit Transaction' : 'Add Transaction'}
                open={open}
                onOk={() => { }} // Handle submit function
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <Radio.Group
                    buttonStyle="solid"
                    style={{ marginBottom: "20px", width: "100%" }}
                    value={selectRadio}
                    onChange={(e) => {
                        setSelectRadio(e.target.value);
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', width: "100%" }}>
                        {optionRadioTransaction.map((item) => {
                            return (
                                <Radio.Button
                                    key={item.id || item.value}
                                    value={item.value}
                                    style={{ width: "100%" }}
                                >
                                    {item.label}
                                </Radio.Button>
                            );
                        })}
                    </div>
                </Radio.Group>

                {selectRadio === TRANSACTION_RADIO_OPTION.TRANSACTION &&
                    <Form onSubmit={formik.handleSubmit} className="space-y-6">
                        {/* Customer Selection */}
                        <Form.Item validateStatus={formik.errors.customer && 'error'}>
                            <label>Customer : </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a customer"
                                optionFilterProp="children"
                                value={formik.values.customer_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue("customer_id", value)
                                    formik.setFieldValue("customer", option.item)
                                }}
                            >
                                {dataCustomerOption.map((item) => {
                                    return (
                                        <Option item={item} value={item.id}>{item.name} - {item.phone}</Option>
                                    );
                                })}
                            </Select>
                            {/* <ErrorMessage name="customer_id" component="div" className="error-message" /> */}
                            <Button type="link" onClick={() => setOpenModalAddCustomer(true)}>
                                New Customer
                            </Button>

                        </Form.Item>

                        {/* Entry Date */}
                        <Form.Item validateStatus={formik.errors.entry_date && 'error'}>
                            <div><label >Entry Date : </label></div>
                            <DatePicker
                                style={{ width: '100%' }}
                                format={"dddd, DD MMMM YYYY"}
                                value={formik.values.entry_date ? moment(formik.values.entry_date) : null}
                                onChange={(value) => { formik.setFieldValue("entry_date", value) }}
                            />
                        </Form.Item>

                        {/* Qty Item */}
                        <Form.Item validateStatus={formik.errors.quantity && 'error'}>
                            <div><label>Qty Item : </label></div>
                            <Input
                                type='number'
                                value={formik.values.quantity}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    formik.setFieldValue("quantity", value);
                                }}
                            />
                        </Form.Item>

                        {/* Order Type */}
                        <div className="space-y-2">
                            <label className="block text-gray-700 font-medium">Order Type</label>
                            <div className="grid grid-cols-2 gap-3">
                                <div
                                    className={`
                                  p-3 rounded-lg border-2 cursor-pointer transition-all duration-200 flex items-center gap-3
                                  ${formik.values.order_type === 'offline'
                                            ? 'border-blue-500 bg-blue-50 text-blue-700'
                                            : 'border-gray-200 text-gray-600 hover:border-gray-300'
                                        }
                              `}
                                    onClick={() => formik.setFieldValue("order_type", "offline")}
                                >
                                    <FaStore className={formik.values.order_type === 'offline' ? 'text-blue-500' : 'text-gray-400'} />
                                    <span className="font-medium">Offline</span>
                                </div>
                                <div
                                    className={`
                                  p-3 rounded-lg border-2 cursor-pointer transition-all duration-200 flex items-center gap-3
                                  ${formik.values.order_type === 'online'
                                            ? 'border-green-500 bg-green-50 text-green-700'
                                            : 'border-gray-200 text-gray-600 hover:border-gray-300'
                                        }
                              `}
                                    onClick={() => formik.setFieldValue("order_type", "online")}
                                >
                                    <FaGlobe className={formik.values.order_type === 'online' ? 'text-green-500' : 'text-gray-400'} />
                                    <span className="font-medium">Online</span>
                                </div>
                            </div>
                            {formik.errors.order_type && (
                                <div className="text-red-500 text-sm flex items-center gap-1">
                                    <FaExclamationCircle />
                                    <span>{formik.errors.order_type}</span>
                                </div>
                            )}
                        </div>
                    </Form>
                }
                {selectRadio === TRANSACTION_RADIO_OPTION.TRANSACTION_DETAIL &&
                    <div>
                        {selectTransactionDetailOption === TRANSACTION_DETAIL_OPTION.ITEMS &&
                            <div className="space-y-4">
                                {/* Add Item Button */}
                                <Button
                                    type="dashed"
                                    onClick={() => handleAddItem()}
                                    className="w-full py-3 flex items-center justify-center gap-2 border-2 border-blue-300 hover:border-blue-400 hover:bg-blue-50 transition-all duration-200"
                                >
                                    <FaPlus className="text-blue-500" />
                                    <span className="text-blue-600 font-medium">Add New Item</span>
                                </Button>

                                {/* Items List */}
                                {formik.values.order_details.map((item, index) => {
                                    if (item.id === '-1') return null;
                                    return (
                                        <div key={index} className="bg-white rounded-xl border border-gray-200 hover:shadow-md transition-all duration-200">
                                            {/* Header */}
                                            <div className="px-4 sm:px-6 py-4 border-b border-gray-100 flex flex-wrap sm:flex-nowrap justify-between items-center gap-2">
                                                <div className="flex flex-wrap items-center gap-2">
                                                    <span className="bg-blue-100 text-blue-600 px-3 py-1 rounded-full text-sm font-medium">
                                                        Item #{formik.values.order_details.length - index}/{formik.values.order_details.length}
                                                    </span>
                                                    {item.treatment?.name && (
                                                        <span className="bg-green-100 text-green-600 px-3 py-1 rounded-full text-sm font-medium">
                                                            {item.treatment.name}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="flex gap-2">
                                                    <button
                                                        onClick={() => handleEdit(index)}
                                                        className="flex items-center gap-2 px-3 py-1.5 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors duration-200 text-sm font-medium border border-blue-200"
                                                    >
                                                        <FaEdit size={14} />
                                                        <span className="hidden sm:inline">Edit</span>
                                                    </button>
                                                    <button
                                                        onClick={() => handleDelete(index)}
                                                        className="flex items-center gap-2 px-3 py-1.5 text-red-600 hover:bg-red-50 rounded-lg transition-colors duration-200 text-sm font-medium border border-red-200"
                                                    >
                                                        <FaTrash size={14} />
                                                        <span className="hidden sm:inline">Delete</span>
                                                    </button>
                                                </div>
                                            </div>

                                            {/* Content */}
                                            <div className="p-4 sm:p-6 flex flex-col sm:flex-row gap-4 sm:gap-6">
                                                {/* Left: Photo (if exists) */}
                                                {formik.values.id > 1 && (
                                                    <div className="flex flex-row sm:flex-col gap-3 items-center">
                                                        <div className="relative w-[100px] sm:w-[150px] h-[80px] sm:h-[100px] rounded-lg overflow-hidden">
                                                            <img
                                                                className="w-full h-full object-cover"
                                                                src={item.shoes_photos[0]?.url_photo || "https://th.bing.com/th/id/OIP.cY6hooCAv2-CFp6BL6Tp3gHaHa?rs=1&pid=ImgDetMain"}
                                                                alt="Product"
                                                            />
                                                        </div>
                                                        <Button
                                                            onClick={() => handlePrintChecklist(formik.values, item)}
                                                            className="flex items-center gap-2 px-3 py-1.5 sm:px-4 sm:py-2 bg-gray-50 hover:bg-gray-100 rounded-lg transition-colors duration-200 text-sm"
                                                        >
                                                            <FaPrint className="text-gray-600" size={14} />
                                                            <span>Print Checklist</span>
                                                        </Button>
                                                    </div>
                                                )}

                                                {/* Middle: Details */}
                                                <div className="flex-grow space-y-2">
                                                    {formik.values.id < 1 ? (
                                                        <div className="space-y-2">
                                                            <div className="flex items-center gap-2 text-sm">
                                                                <FaClock className="text-gray-400 flex-shrink-0" size={14} />
                                                                <span className="text-gray-600">Process Time: {DisplayProcessTime(item.process_time)}</span>
                                                            </div>
                                                            {item.discount?.label && (
                                                                <div className="flex items-center gap-2 text-sm">
                                                                    <FaTag className="text-gray-400 flex-shrink-0" size={14} />
                                                                    <span className="text-gray-600 break-words">Discount: {item.discount.label}</span>
                                                                </div>
                                                            )}
                                                            {item.description && (
                                                                <div className="flex items-start gap-2 text-sm">
                                                                    <FaInfoCircle className="text-gray-400 flex-shrink-0 mt-1" size={14} />
                                                                    <span className="text-gray-600 break-words">{item.description}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="space-y-2">
                                                            <p className="text-gray-600 text-sm break-words">
                                                                {item.item?.name} - {item.item?.color}
                                                            </p>
                                                            {item.description && (
                                                                <p className="text-gray-500 text-sm break-words">{item.description}</p>
                                                            )}
                                                            <div className="flex items-center gap-2 text-sm">
                                                                <FaCalendar className="text-gray-400 flex-shrink-0" size={14} />
                                                                <span className="text-gray-600">Due: {dayjs(item.due_date).format("DD/MM/YYYY")}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {/* Right: Price */}
                                                <div className="flex items-center justify-end sm:justify-center border-t pt-3 sm:border-t-0 sm:pt-0">
                                                    <div className="text-right">
                                                        <span className="text-xl sm:text-2xl font-bold text-gray-800">
                                                            {formatNumber(parseInt(item.final_price))}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        }
                        {selectTransactionDetailOption === TRANSACTION_DETAIL_OPTION.ADD_ITEM &&
                            <AddItem
                                formik={formik}
                                index={formik.values.order_details.length - 1}
                                selectTransactionDetailOption={selectTransactionDetailOption}
                                setSelectTransactionDetailOption={setSelectTransactionDetailOption}
                            />
                        }
                        {selectTransactionDetailOption === TRANSACTION_DETAIL_OPTION.EDIT_ITEM &&
                            <AddItem
                                formik={formik}
                                index={indexEditItem}
                                selectTransactionDetailOption={selectTransactionDetailOption}
                                setSelectTransactionDetailOption={setSelectTransactionDetailOption}
                                initialData={initialData}
                            />
                        }
                    </div>
                }
                {selectRadio === TRANSACTION_RADIO_OPTION.PAYMENT &&
                    <>
                        <Card
                            title={
                                <div className="flex items-center gap-2">
                                    <FaHistory className="text-blue-500" />
                                    <span>Payment History</span>
                                </div>
                            }
                            className="mb-5"
                        >
                            {/* Payment Progress Header */}
                            <div className="flex justify-between items-center mb-3">
                                <div className="flex items-center gap-2">
                                    <FaChartPie className="text-blue-500" />
                                    <span className="text-gray-700 font-medium">Payment Progress</span>
                                </div>
                                <div className="bg-blue-50 px-3 py-1 rounded-full">
                                    <span className="text-blue-600 font-medium">
                                        {Math.round((formik.values.payment?.payment_details?.reduce(
                                            (total, detail) => total + parseFloat(detail.final_amount),
                                            0
                                        ) / parseFloat(formik.values.payment?.final_price)) * 100 || 0)}%
                                    </span>
                                </div>
                            </div>

                            {/* Progress Bar */}
                            <div className="relative mb-4">
                                <div className="w-full bg-gray-100 rounded-full h-3 overflow-hidden border border-gray-200">
                                    <div
                                        className={`h-full rounded-full transition-all duration-500 ${getProgressColor(
                                            (formik.values.payment?.payment_details?.reduce(
                                                (total, detail) => total + parseFloat(detail.final_amount),
                                                0
                                            ) / parseFloat(formik.values.payment?.final_price)) * 100 || 0
                                        )
                                            }`}
                                        style={{
                                            width: `${Math.max(5, (formik.values.payment?.payment_details?.reduce(
                                                (total, detail) => total + parseFloat(detail.final_amount),
                                                0
                                            ) / parseFloat(formik.values.payment?.final_price)) * 100 || 0)}%`
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Payment Details */}
                            <div className="space-y-3">
                                {formik.values.payment?.payment_details?.map((detail, index) => (
                                    <div
                                        key={index}
                                        className="bg-gray-50 rounded-lg p-4 border border-gray-100 hover:shadow-md transition-all duration-200"
                                    >
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center gap-3">
                                                {detail.method === 'cash' && <FaMoneyBillWave className="text-green-500 text-xl" />}
                                                {detail.method === 'bank_transfer' && <FaUniversity className="text-blue-500 text-xl" />}
                                                {detail.method === 'qris' && <FaQrcode className="text-purple-500 text-xl" />}

                                                <div>
                                                    <div className="font-medium text-gray-800">
                                                        {PaymentMethodDisplay(detail.method)}
                                                    </div>
                                                    <div className="text-sm text-gray-500 flex items-center gap-1">
                                                        <FaClock className="text-gray-400" />
                                                        {moment(detail.createdAt).format("DD/MM/YYYY HH:mm")}
                                                        {console.log("DETAIL", detail)}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-right">
                                                <div className="font-bold text-lg text-gray-800">
                                                    {formatCurrency(parseFloat(detail.final_amount))}
                                                </div>
                                                {detail.fee > 0 && (
                                                    <div className="text-sm text-gray-500">
                                                        Fee: {formatCurrency(parseFloat(detail.fee))}
                                                    </div>
                                                )}
                                            </div>

                                            <Button
                                                type="text"
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                    const newPaymentDetails = formik.values.payment.payment_details.filter((_, i) => i !== index);
                                                    formik.setFieldValue('payment.payment_details', newPaymentDetails);

                                                    // Recalculate total if needed
                                                    const paidAmount = newPaymentDetails.reduce((sum, pd) => sum + parseFloat(pd.final_amount || 0), 0);
                                                    const remainingAmount = formik.values.payment.final_price - paidAmount;
                                                    const status = remainingAmount === 0 ? 'paid' : remainingAmount > 0 && remainingAmount < formik.values.payment.total_price ? 'dp' : 'unpaid';
                                                    const method = newPaymentDetails.map(detail => detail.method).join(', ');

                                                    formik.setFieldValue('payment.paid_amount', paidAmount);
                                                    formik.setFieldValue('payment.remaining_amount', remainingAmount);
                                                    formik.setFieldValue('payment.status', status);
                                                    formik.setFieldValue('payment.method', method);
                                                }}
                                                className="flex items-center justify-center hover:bg-red-50"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Payment Summary */}
                            <div className="mt-6 pt-4 border-t border-gray-100">
                                <div className="space-y-3">
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Final Price</span>
                                        <span className="font-bold text-green-600">
                                            {formatCurrency(
                                                formik.values.payment?.final_price || 0
                                            )}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Total Paid</span>
                                        <span className="font-bold text-green-600">
                                            {formatCurrency(
                                                formik.values.payment?.payment_details?.reduce(
                                                    (total, detail) => total + parseFloat(detail.final_amount),
                                                    0
                                                ) || 0
                                            )}
                                        </span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <span className="text-gray-600">Remaining Payment</span>
                                        <span className="font-bold text-red-500">
                                            {formatCurrency(
                                                parseFloat(formik.values.payment?.final_price) -
                                                (formik.values.payment?.payment_details?.reduce(
                                                    (total, detail) => total + parseFloat(detail.final_amount),
                                                    0
                                                ) || 0)
                                            )}
                                        </span>
                                    </div>
                                </div>

                                {/* Payment Status Badge */}
                                <div className="mt-4 flex justify-center">
                                    <span className={`
                                        px-4 py-2 rounded-full text-sm font-medium
                                        ${formik.values.payment?.status === 'paid' ? 'bg-green-100 text-green-800' :
                                            formik.values.payment?.status === 'unpaid' ? 'bg-red-100 text-red-800' :
                                                'bg-yellow-100 text-yellow-800'}
                                    `}>
                                        {formik.values.payment?.status === 'paid' ? 'Fully Paid' :
                                            formik.values.payment?.status === 'unpaid' ? 'Unpaid' : 'Partially Paid'}
                                    </span>
                                </div>
                            </div>

                            {/* Add Payment Button */}
                            {formik.values.payment?.status !== 'paid' && (
                                <div className="mt-6 flex justify-center">
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={() => setIsPaymentModalVisible(true)}
                                        className="bg-blue-500 hover:bg-blue-600 flex items-center gap-2"
                                    >
                                        Add Payment
                                    </Button>
                                </div>
                            )}
                        </Card>

                        <PaymentModal
                            visible={isPaymentModalVisible}
                            loading={loading}
                            financeAccounts={financeAccounts}
                            paymentDetail={newPaymentDetail}
                            setPaymentDetail={setNewPaymentDetail}
                            formik={formik}
                            onCancel={() => {
                                setIsPaymentModalVisible(false);
                                setNewPaymentDetail({
                                    payment_id: formik.values.payment?.id,
                                    account_id: null,
                                    method: '',
                                    amount: 0,
                                    fee: 0,
                                    interest: 0,
                                    final_amount: 0,
                                    evidence_photo: null
                                });
                            }}
                            onSubmit={() => {
                                handleSubmit();
                                setIsPaymentModalVisible(false);
                            }}
                        />

                        <div className="space-y-6">
                            {/* Invoice Actions */}
                            {formik.values?.payment?.code && (
                                <div className="bg-gray-50 rounded-lg p-4">
                                    <div className="flex flex-col sm:flex-row gap-3">
                                        <Invoice open={openModalInvoice} setOpen={setOpenModalInvoice} formik={formik} />
                                        <Button
                                            onClick={() => setOpenModalInvoice(true)}
                                            className="flex items-center justify-center gap-2 px-4 py-2 bg-white border-2 border-gray-200 rounded-lg hover:bg-gray-50 hover:border-gray-300 transition-all duration-200"
                                        >
                                            <FaFileDownload className="text-blue-500" />
                                            <span>Download Invoice</span>
                                        </Button>
                                        <Button
                                            onClick={async () => PrintInvoice(formik.values)}
                                            className="flex items-center justify-center gap-2 px-4 py-2 bg-white border-2 border-gray-200 rounded-lg hover:bg-gray-50 hover:border-gray-300 transition-all duration-200"
                                        >
                                            <FaPrint className="text-green-500" />
                                            <span>Print Invoice</span>
                                        </Button>
                                        <Button
                                            onClick={async () => PrintChecklist(formik.values)}
                                            className="flex items-center justify-center gap-2 px-4 py-2 bg-white border-2 border-gray-200 rounded-lg hover:bg-gray-50 hover:border-gray-300 transition-all duration-200"
                                        >
                                            <FaClipboardCheck className="text-purple-500" />
                                            <span>Checklist</span>
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {/* Main Actions */}
                            <div className="flex flex-col sm:flex-row justify-end gap-3 pt-4 border-t border-gray-200">
                                <Button
                                    onClick={() => setOpen(false)}
                                    className="px-6 bg-gray-50 hover:bg-gray-100 border-2 border-gray-200 rounded-lg transition-all duration-200 items-center justify-center"
                                >
                                    <span className="font-medium text-gray-600">Cancel</span>
                                </Button>

                                <Button
                                    loading={formik.isSubmitting}
                                    onClick={() => formik.handleSubmit()}
                                    className={`
                                            px-6 py-2 rounded-lg transition-all duration-200
                                            flex items-center justify-center gap-2
                                            ${formik.isSubmitting
                                            ? 'bg-blue-400 opacity-75'
                                            : 'bg-blue-500 hover:bg-blue-600'
                                        } `}
                                >
                                    {formik.isSubmitting ? (
                                        <div className="flex items-center gap-2">
                                            <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                            <span className="text-white font-medium">Processing...</span>
                                        </div>
                                    ) : (
                                        <div className="flex items-center gap-2">
                                            <FaSave className="text-white" />
                                            <span className="text-white font-medium">
                                                {formik.values.id > 1 ? "Update" : "Save"}
                                            </span>
                                        </div>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </>
                }

            </Modal>
            <ModalAddCustomer open={openModalAddCustomer} setOpen={setOpenModalAddCustomer} />

        </div >
    );
};

export default ModalAddTransaction;
